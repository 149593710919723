import { useCallback } from 'react';
import Lottie from 'react-lottie';

import animationData from 'src/assets/img/lottie/wallet-claim.json';

import { AppButton } from 'src/components/button';

import { useAppRoute } from 'src/hooks/useAppRoute.ts';

import { TEXT_VARS } from 'src/i18n/en.ts';

import { AppRoutes } from 'src/router/config.ts';

import {
    StyledTitle,
    StyledContinueButton,
    StyledText,
} from 'src/screens/wallet-claim/wallet-claim.styled.ts';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components.ts';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const WalletClaim = () => {
    const route = useAppRoute();

    const handleContinueClick = useCallback(() => {
        route(AppRoutes.customerSelectYourBankAccount);
    }, [route]);

    return (
        <StyledPageWithPaddingContainer>
            <StyledTitle>
                <span>{TEXT_VARS.TITLE.WALLET_CLAIM}</span>
            </StyledTitle>
            <StyledText>{TEXT_VARS.COMMON_TEXT.LET_US_CONNECT}</StyledText>
            <Lottie height={300} options={defaultOptions} width={300} />
            <StyledContinueButton>
                <AppButton appButtonType="Continue" onClick={handleContinueClick}>
                    {TEXT_VARS.BUTTON.CONNECT_TO_BANK}
                </AppButton>
            </StyledContinueButton>
        </StyledPageWithPaddingContainer>
    );
};
