import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography.ts';

export const StyledContinueButton = styled.div`
    align-self: center;
    margin-top: 76px;
`;

export const StyledTitle = styled.div`
    margin: 35px 0;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
`;

export const StyledText = styled.span`
    ${AppTypography.chargeeRegularThinText};
    font-size: 21px;
    white-space: break-spaces;
    text-align: center;
    line-height: 1.3;
`;
