import axios from 'axios';
import { getAuth, signOut as firebaseSignOut, User } from 'firebase/auth';
import { store } from 'src/store';

import { ENDPOINT } from 'src/configuration/endpoints';

import { clearAccountState, saveAggregatedBankAccounts } from 'src/store/accounts/reducer';
import { clearCards } from 'src/store/cards/reducer';
import { saveChangeRequest } from 'src/store/change-request/reducer.ts';
import { clearInstitutionState } from 'src/store/institution/reducer';
import { clearPayments } from 'src/store/payment/reducer';
import { AppThunk, resultSuccess, resultError } from 'src/store/root-reducer.models';
import {
    AuthenticationRequest,
    CreatePrePaymentDTO,
    DocType,
    GetUserThunkProps,
    PaymentLinkStatus,
    PrePaymentDTO,
    SecureCard,
    UserAgreementDTO,
} from 'src/store/user/models';
import {
    addBankAccount,
    saveAgreementAcceptation,
    saveAgreements,
    saveConnectWidgetData,
    saveCurrentCustomer,
    saveGeneratedJWTToken,
    saveMerchant,
    savePaymentLink,
    savePrepayment,
    signInAction,
    signOutAction,
} from 'src/store/user/reducer';

export const createOrUpdateCustomer: AppThunk<GetUserThunkProps> =
    ({ name, firstName, lastName, email, phoneNumber }) =>
    async (dispatch, getState) => {
        const id = getState().user.customer?.id;

        if (id) {
            try {
                const currentUser = await axios.put(ENDPOINT.customers, {
                    name,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    id,
                });
                dispatch(saveCurrentCustomer(currentUser.data));
            } catch (e) {
                return resultError({ message: e });
            }
        } else {
            try {
                const currentUser = await axios.post(ENDPOINT.customers, {
                    name,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                });
                dispatch(saveCurrentCustomer(currentUser.data));
            } catch (e) {
                return resultError({ message: e });
            }
        }

        return resultSuccess();
    };

export const signIn = async (user: User) => {
    const idToken = await user.getIdToken();

    if (idToken) {
        store.dispatch(saveGeneratedJWTToken({ idToken, expiresIn: 0, refreshToken: '' }));
    }

    const { displayName, email, emailVerified, phoneNumber, photoURL, isAnonymous, uid, providerData } = user;
    const signedInUser = {
        displayName,
        email,
        emailVerified,
        isAnonymous,
        phoneNumber,
        photoURL,
        providerData,
        uid,
        idToken,
    };
    store.dispatch(signInAction(signedInUser));
};

export const getCurrentCustomer: AppThunk = () => async dispatch => {
    try {
        const currentCustomer = await axios.get(ENDPOINT.getCurrentCustomer);
        dispatch(saveCurrentCustomer(currentCustomer.data));
        return resultSuccess(currentCustomer.data);
    } catch (e) {
        dispatch(saveCurrentCustomer(null));
        return { success: false, error: { message: e } };
    }
};

export const signOut = async () => {
    const auth = getAuth();

    try {
        await firebaseSignOut(auth);
        store.dispatch(signOutAction());
        store.dispatch(clearInstitutionState());
        store.dispatch(clearAccountState());
        store.dispatch(clearCards());
        store.dispatch(clearPayments());
        return resultSuccess();
    } catch (e) {
        return e;
    }
};

export const addCreditCard: AppThunk<{ customerId: string; data: SecureCard }> =
    ({ customerId, data }) =>
    async () => {
        try {
            const result = await axios.post(ENDPOINT.customerCreditCard(customerId), data);
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const deleteCreditCard: AppThunk<{ customerId: string; cardId: string }> =
    ({ customerId, cardId }) =>
    async () => {
        try {
            await axios.delete(ENDPOINT.customerCreditCardDelete(customerId, cardId));
            return resultSuccess();
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const addBankAccountToCustomer: AppThunk<{ customerId: string; accountId: string }> =
    ({ customerId, accountId }) =>
    async dispatch => {
        try {
            const result = await axios.post(ENDPOINT.customerBankAccount(customerId, accountId));
            dispatch(addBankAccount(result.data));
        } catch (e) {
            return resultError({ message: e });
        }
        return resultSuccess();
    };

export const getBankAccounts: AppThunk<{ customerId: string }> =
    ({ customerId }) =>
    async dispatch => {
        try {
            const result = await axios.get(ENDPOINT.getCustomerBankAccount(customerId));

            if (result.data.status === 202) {
                return resultSuccess(result.data);
            }

            dispatch(saveAggregatedBankAccounts(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const getCurrentMerchant: AppThunk = () => async dispatch => {
    try {
        const result = await axios.get(ENDPOINT.currentMerchant);
        dispatch(saveMerchant(result.data));
        return resultSuccess(result.data);
    } catch (e) {
        return resultError({ message: e });
    }
};

export const getConnectWidgetUrl: AppThunk<{ customerId: string }> =
    ({ customerId }) =>
    async dispatch => {
        try {
            const result = await axios.get(ENDPOINT.connectWidget(customerId));
            dispatch(saveConnectWidgetData(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const getPaymentLinkDecoded: AppThunk<{ token: string }> =
    ({ token }) =>
    async () => {
        try {
            const result = await axios.get(ENDPOINT.paymentLink(token));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const setPaymentLinkStatus: AppThunk<{ token: string; status: PaymentLinkStatus }> =
    ({ token, status }) =>
    async dispatch => {
        try {
            const result = await axios.put(ENDPOINT.paymentLinkStatus(token), { status });
            dispatch(savePaymentLink(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const getFiservWidgetSessionId: AppThunk<{ id: string }> =
    ({ id }) =>
    async () => {
        try {
            const result = await axios.get(ENDPOINT.fiservSessionId(id));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };

export const prepaymentRequest: AppThunk<{ prepayment: CreatePrePaymentDTO }> =
    ({ prepayment }) =>
    async () => {
        try {
            const result = await axios.post(ENDPOINT.prepayment, prepayment);
            return resultSuccess(result.data as PrePaymentDTO);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const prepaymentShortCodeRequest: AppThunk<{ shortCode: string }> =
    ({ shortCode }) =>
    async dispatch => {
        try {
            const result = await axios.get(ENDPOINT.prepaymentShortCode(shortCode));
            dispatch(savePrepayment(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const generateJWTToken: AppThunk<AuthenticationRequest> =
    ({
        apiKey,
        apiToken,
        externalMerchantIds,
        merchantId,
        paymentLinkToken,
        scope = 'SCOPE_PAYMENT',
        secret,
    }) =>
    async dispatch => {
        const isApiKey = !(apiKey?.includes('undefined') || !apiKey);
        const isApiToken = !(apiToken?.includes('undefined') || apiToken?.includes('null') || !apiToken);

        const data = {
            ...(isApiKey && { apiToken: apiKey }),
            ...(isApiToken && merchantId && { merchantId: merchantId }),
            ...(isApiToken && { apiToken: apiToken }),
            ...(paymentLinkToken && { paymentLinkToken }),
            scope,
        };

        try {
            const result = await axios.post(ENDPOINT.generateJWTToken, data);
            dispatch(saveGeneratedJWTToken(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const getUserAgreements: AppThunk<DocType> = docType => async dispatch => {
    try {
        const result = await axios.get(ENDPOINT.agreementsDocType(docType));
        dispatch(saveAgreements(result.data));
        return resultSuccess(result.data);
    } catch (e) {
        return resultError({ message: e });
    }
};

export const sendUserAgreements: AppThunk<UserAgreementDTO> = agreement => async dispatch => {
    try {
        const result = await axios.post(ENDPOINT.agreements, agreement);
        dispatch(saveAgreementAcceptation(true));
        return resultSuccess(result.data);
    } catch (e) {
        return resultError({ message: e });
    }
};

export const getPubKey: AppThunk = () => async () => {
    try {
        const result = await axios.get(ENDPOINT.pubKey);
        return resultSuccess(result.data);
    } catch (e) {
        return resultError({ message: e });
    }
};

export const getCustomerChange: AppThunk<{ customerId: string }> =
    ({ customerId }) =>
    async dispatch => {
        try {
            const result = await axios.get(ENDPOINT.getChange(customerId));
            dispatch(saveChangeRequest(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return resultError({ message: e });
        }
    };
