import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledContainer = styled.div`
    margin: 0 auto;
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledCopyright = styled.div`
    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};

    display: flex;
    justify-content: center;
    width: 100%;
    font-family: 'Helvetica Neue', serif;
    margin-top: 10px;
`;

export const StyledGetAppText = styled.div`
    font-size: 20px;
    color: #161515;
    font-family: 'ArialMT', serif;
    font-weight: bold;
`;

export const StyledDownloadChargeeText = styled.div`
    text-align: center;
    white-space: break-spaces;
    font-size: 18px;
    color: #167979;
    font-family: 'ArialMT', serif;
    line-height: 1.2em;
`;

export const StyledBadgesSection = styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledLink = styled.a`
    text-decoration: none;
`;

const show = keyframes`
        0%, 99% {
            opacity: 0;
            display: none;
        }
        100% {
            opacity: 1;
        }
        
    `;

export const StyledImg = styled.img`
    width: 100%;
    animation: ${show} 3s forwards;
`;

export const StyledLinkImg = styled.img`
    width: 150px;
`;
