import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChangeState, UncompletedChangeRequestsDTO } from 'src/store/change-request/models.ts';

export const INITIAL_STATE: ChangeState = {};

const changeRequestReducer = createSlice({
    name: 'ChangeState',
    initialState: INITIAL_STATE,
    reducers: {
        saveChangeRequest: (state, action: PayloadAction<UncompletedChangeRequestsDTO>) => {
            state.changeRequest = action.payload;
        },
    },
});

export const { saveChangeRequest } = changeRequestReducer.actions;
export default changeRequestReducer.reducer;
