import { useSelector } from 'react-redux';

import downloadApp from 'src/assets/img/download-app-img.png';
import downloadOnAppStore from 'src/assets/img/download-on-appstore.png';
import downloadOnGoogleStore from 'src/assets/img/download-on-google.png';

import { getApplicationConfig } from 'src/configuration/config';

import { TEXT_VARS } from 'src/i18n/en';

import { CheckMarkPopup } from 'src/screens/get-the-app/components/check-mark-popup';

import { selectLinkFlow } from 'src/store/app/selectors';

import { ApplicationConfig } from 'src/types/applicationConfig.type';

import { formatString } from 'src/utils/formatString';

import {
    StyledBadgesSection,
    StyledContainer,
    StyledCopyright,
    StyledDownloadChargeeText,
    StyledGetAppText,
    StyledImg,
    StyledLink,
    StyledLinkImg,
} from './get-the-app.styled';

const appConfig: ApplicationConfig = getApplicationConfig();

export const GetTheApp = () => {
    const linkFlow = useSelector(selectLinkFlow);

    return (
        <StyledContainer>
            <StyledImg alt="image" src={downloadApp} />
            <StyledGetAppText>{TEXT_VARS.COMMON_TEXT.GET_THE_APP}</StyledGetAppText>
            <StyledDownloadChargeeText>
                {formatString(TEXT_VARS.COMMON_TEXT.DOWNLOAD_APP, appConfig.brandName)}
            </StyledDownloadChargeeText>
            <StyledBadgesSection>
                <StyledLink href={appConfig.iOSStore} rel="noopener noreferrer" target="_blank">
                    <StyledLinkImg alt="download-on-appstore" src={downloadOnAppStore} />
                </StyledLink>
                <StyledLink href={appConfig.googleStore} rel="noopener noreferrer" target="_blank">
                    <StyledLinkImg alt="download-on-google" src={downloadOnGoogleStore} />
                </StyledLink>
            </StyledBadgesSection>
            <StyledCopyright>
                {formatString(TEXT_VARS.COMMON_TEXT.COPYRIGHTS, new Date().getFullYear(), appConfig.appTld)}
            </StyledCopyright>
            <CheckMarkPopup isConsentLinkFlow={linkFlow === 'CONSENT_LINK'} />
        </StyledContainer>
    );
};
