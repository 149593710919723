import currency from 'currency.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AccountComponent } from 'src/components/account/account-component.tsx';
import { AppButton } from 'src/components/button';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { PrivateAndSecure } from 'src/components/private-and-secure';

import { BRANDS } from 'src/configuration/constants.ts';

import { useAppRoute } from 'src/hooks/useAppRoute.ts';
import { useMountedRef } from 'src/hooks/useMountedRef.ts';
import { useThunkAction } from 'src/hooks/useThunkAction.ts';

import { TEXT_VARS } from 'src/i18n/en.ts';

import { AppRoutes } from 'src/router/config.ts';

import {
    StyledBorder,
    StyledConfirmButton,
    StyledLinkButton,
    StyledPrivate,
    StyledSpan,
    StyledTermsConditions,
    StyledTitle,
    TextBody,
} from 'src/screens/change-confirmation/change-confirmation.styled.ts';
import { StyledAmount, StyledTitleWrapper } from 'src/screens/change-request/change-request.styled.ts';

import { getCustomerLedgerAccountsThunk } from 'src/store/accounts/actions.thunks.ts';
import { selectSelectedBankAccount } from 'src/store/accounts/selectors.ts';
import { selectBrandName } from 'src/store/config/selectors.ts';
import { paybackPayment } from 'src/store/payment/actions.thunks.ts';
import { getCurrentCustomer, signOut } from 'src/store/user/actions.thunks.ts';
import { selectCustomer } from 'src/store/user/selectors.ts';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components.ts';

const FIREBASE_LOG_OUT = 'firebaseLogOut';

export const ChangeConfirmation = () => {
    const route = useAppRoute();
    const isMountedRef = useMountedRef();

    const [systemError, setSystemError] = useState<string>('');

    const [paybackPaymentAction, [isPaybackPaymentLoading], [paybackPaymentError]] =
        useThunkAction(paybackPayment);

    const [
        getCurrentUserAction,
        [isGetCurrentUserActionLoading],
        [currentCustomerThunkError, setCurrentCustomerThunkError],
    ] = useThunkAction(getCurrentCustomer);

    const [getCustomerLedgerAction, [isGetCustomerLedgerLoading], [getCustomerLedgerError]] = useThunkAction(
        getCustomerLedgerAccountsThunk
    );

    const customer = useSelector(selectCustomer);
    const bankAccount = useSelector(selectSelectedBankAccount);

    const [amount, setAmount] = useState('');
    const brandName = useSelector(selectBrandName);

    useEffect(() => {
        customer &&
            getCustomerLedgerAction({ customerId: customer.id }).then(r => {
                if (!r.success) return;
                const payload = r.payload as Array<{
                    totalsByCurrency: { total: number }[];
                }>;
                const totalAmount = payload[0].totalsByCurrency.reduce(
                    (accumulator: number, currentValue) => {
                        return accumulator + currentValue.total;
                    },
                    0
                );
                setAmount(totalAmount.toString());
            });
    }, [customer, getCustomerLedgerAction]);

    useEffect(() => {
        getCurrentUserAction().then(r => {
            if (!isMountedRef.current) {
                return r;
            }

            if (!r.success) {
                if (r.error.message.response && r.error.message.response?.status >= 500) {
                    setCurrentCustomerThunkError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }
            }
            return r;
        });
    }, [getCurrentUserAction, isMountedRef, setCurrentCustomerThunkError]);

    const handleAccountChange = useCallback(() => {
        route(AppRoutes.customerSelectYourBankAccount);
    }, [route]);

    const customerBankAccount = bankAccount || (customer && customer.defaultBankAccount);

    const handleContinueClick = useCallback(() => {
        if (!customerBankAccount) {
            setSystemError('Selected Bank Account unavailable, but expected');
            return;
        }

        paybackPaymentAction({
            amount,
            autoWire: true,
            bankAccountId: { id: customerBankAccount.id },
        }).then(r => {
            if (!r.success) return;
            route(AppRoutes.downloadApp);
        });
    }, [amount, customerBankAccount, paybackPaymentAction, route]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    useEffect(() => {
        const signOutFunc = async function (event: MessageEvent<any>) {
            if (event.data.signOut === FIREBASE_LOG_OUT) {
                await signOut();
            }
        };
        window.addEventListener('message', signOutFunc);
        return () => window.removeEventListener('message', signOutFunc);
    }, []);

    const isErrorDisplayed: boolean = !!(
        currentCustomerThunkError ||
        getCustomerLedgerError ||
        systemError ||
        paybackPaymentError
    );

    const isBrandChargee = brandName === BRANDS.CHARGEE;

    const textError = paybackPaymentError || currentCustomerThunkError || getCustomerLedgerError;

    return (
        <>
            <StyledPageWithPaddingContainer $isPointerEventsDisabled={isErrorDisplayed}>
                <Loader
                    isShowing={
                        isGetCurrentUserActionLoading || isPaybackPaymentLoading || isGetCustomerLedgerLoading
                    }
                />
                <StyledTitle>
                    <StyledTitleWrapper>
                        <span>Get your funds:</span>
                        <span>
                            <StyledAmount>{`${currency(amount).format()}`}</StyledAmount>
                        </span>
                    </StyledTitleWrapper>
                </StyledTitle>
                <TextBody>
                    {customerBankAccount && (
                        <AccountComponent
                            bankAccount={customerBankAccount}
                            handleAccountChange={handleAccountChange}
                            totalAmount={amount}
                        />
                    )}
                    <StyledBorder />

                    <StyledTermsConditions>
                        <span className="terms-and-conditions">
                            <StyledSpan>{TEXT_VARS.COMMON_TEXT.BY_CONTINUING_YOU_AGREE}</StyledSpan>
                            <StyledLinkButton href="https://billmybank.com/terms-of-use/" target="_blank">
                                {TEXT_VARS.COMMON_TEXT.TERM_AND_CONDITIONS}
                            </StyledLinkButton>
                            .
                        </span>
                    </StyledTermsConditions>
                </TextBody>

                <StyledConfirmButton>
                    <AppButton appButtonType="Continue" onClick={handleContinueClick}>
                        {TEXT_VARS.BUTTON.PUSH_TO_BANK}
                    </AppButton>
                </StyledConfirmButton>
                {isBrandChargee && (
                    <StyledPrivate>
                        <PrivateAndSecure />
                    </StyledPrivate>
                )}
            </StyledPageWithPaddingContainer>
            {isErrorDisplayed && (
                <InfoActionLayout
                    bodyText={textError}
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </>
    );
};
