export enum BUTTON {
    SIGN_IN = 'Sign In',
    SIGN_UP = 'Sign Up',
    CONTINUE = 'Continue',
    CONFIRM = 'Confirm',
    SAVE = 'Save',
    CANCEL = 'Cancel',
    CANCEL_AND_LOGOUT = 'Cancel & Logout',
    SHOW = 'Show',
    HIDE = 'Hide',
    PROCESS_PAYMENT = 'Process Payment',
    PROCESSING_PAYMENT = 'Processing Payment',
    PROCESS_TRANSACTION = 'Process transaction',
    PROCESSING_TRANSACTION = 'Processing transaction',
    PUSH_TO_BANK = 'Push to bank',
    SIGN_IN_WITH_GOOGLE = 'Sign in with google',
    SIGN_IN_WITH_FACEBOOK = 'Sign in with Facebook',
    SIGN_IN_WITH_PHONE = 'Sign in with phone',
    SIGN_IN_WITH_EMAIL = 'Sign in with email',
    UPDATE = 'update',
    DELETE = 'delete',
    LETS_GO = 'Lets Go!',
    OK = 'OK',
    YES = 'YES',
    NEVERMIND = 'nevermind',
    BUY_NOW_PAY_LATER = 'Buy Now Pay Later',
    PAY_DIRECT = 'Pay Direct',
    ADD = 'Add',
    I_AGREE = 'I agree',
    TRY_AGAIN = 'Try Again',
    PROCEED = 'Proceed',
    CONNECT_TO_BANK = 'Connect to Bank',
}

export enum TITLE {
    SIGN_IN = 'Sign in',
    SIGN_OUT = 'Sign out',
    IT_IS_SIMPLE = "It's simple, secure, and reliable",
    COMPLETE_YOUR_PURCHASE = 'Complete your Purchase',
    COMPLETE_YOUR_PROFILE = 'Complete your profile',
    ENTER_YOUR_CONTACT_BILLING_INFO = 'Enter your contact and billing info',
    LINK_MY_BANK = 'Link My Bank',
    LINK_MY_CREDIT_CARD_INFO = 'Link My Credit Card Info',
    ADD_YOUR_CREDIT_CARD_INFO = 'Add Your Credit Card Info',
    CHOOSE_INSTITUTION_BELOW = 'Choose Institution below',
    CREDIT_CARD_INFO = 'Credit Card Info',
    CONTACT_INFO = 'Contact Info',
    CARD_NUMBER = 'Card Number',
    CARDHOLDER_NAME = 'Cardholder Name',
    CVV = 'CVV',
    WE_WILL_CHARGE_YOUR_ACCOUNT = 'We will charge your account',
    CHOOSE_YOUR_ACCOUNT = 'Choose your account',
    REVIEW_YOUR_PURCHASE = 'Review Your Purchase',
    SELECT_YOUR_BANK_ACCOUNT = 'Select Your Bank Account',
    SELECT_YOUR_CARD = 'Select Your Card',
    OR_ADD_NEW_ONE = 'or add a new one',
    BANK_ACCOUNTS = 'Bank Accounts',
    CARDS = 'Cards',
    BUY_NOW_PAY_LATER = 'Buy Now Pay Later',
    CHOOSE_YOU_BNPL_PLAN = 'Choose Your BNPL Plan',
    PAYMENT_CONFIRMATION = 'Payment confirmation',
    ADD_BANK_ACCOUNTS = 'Add Bank Accounts',
    CHECK_ONE_OR_MULTI = 'Please Check One or Multi',
    SELECT_FIN_OPTION = 'Select financing option',
    TERMS_AND_CONDITIONS = 'Terms & Conditions',
    START_SETTING_UP = 'Start setting up automatic payments for',
    EXPIRED_SCREEN_START = 'Congratulations,',
    EXPIRED_SCREEN = 'your setup is complete!',
    CHANGE = 'Change',
    WALLET_CLAIM = 'Wallet Claim Successful!',
}

export enum ERRORS {
    THE_CREDENTIALS_ENTERED_DO_NOT_MATCH = 'The credentials entered do not match your credentials at this institution. ',
    THIS_CARD_HAS_AN_AVAILABLE_BALANCE_LESS = 'This account has an available balance less than the total purchase. ',
    THIS_CARD_EXPIRED = 'This card expired, please add a new card.',
    NETWORK_ERROR = 'Network Error.',
    BACKEND_ERROR = 'Backend Error.',
    SERVER_ERROR = 'Server Error.',
    WE_ARE_UNABLE_TO_COMPLETE = 'We are unable to complete your purchase due to a server error.',
    PLEASE_ENTER_CARD_HOLDER_NAME = 'Please enter card holder name',
    YOUR_CARD_IS_INVALID = 'Your card is invalid',
    DATE_IS_INVALID = 'Date is invalid',
    CVC_IS_INVALID = 'CVC is invalid',
    EMAIL_IS_INVALID = 'Invalid email format',
    NAME_IS_INVALID = 'Invalid name format. Only letters are allowed.',
    PHONE_NUMBER_IS_INVALID = 'Invalid phone number format. Should be 10 characters or 11 if starts from `1`',
    CONNECT_WIDGET_SCRIPT_IS_NOT_LOADED = 'Connect widget script is not loaded. Please try again later or contact us.',
    COULD_NOT_GET_MEMBER = 'Could not get member',
    NO_API_TOKEN_OR_NO_AMOUNT_WAS_PROVIDED = 'Please provide apiToken and amount',
    UNEXPECTED_ERROR = 'Unexpected Error Occurred',
    NO_PAYMENT_CODE = 'Please provide Payment Code',
    TRANSACTIONS_EXPIRED = 'Unfortunately, it appears that this transaction has expired or been cancelled.',
}

export enum COMMON_TEXT {
    IF_YOU_DONT_HAVE_AN_ACCOUNT = "If you don't have an account?",
    THE_CONVENIENCE_OF_SAVING = 'The convenience of saving Your credit card information for future payments saves you time and money.',
    YOUR_NAME = 'Your Name',
    YOUR_EMAIL = 'Your Email',
    YOUR_PHONE_NUMBER = 'Your Phone Number',
    USER_ID = 'User ID',
    PASSWORD = 'Password',
    ACCOUNT_DETAILS = 'Account details:',
    INSTITUTION = 'Institution:',
    ACCOUNT_TYPE = 'Account type:',
    BALANCE = 'Balance:',
    PRODUCT_COST = 'Product Cost',
    TOTAL_AMOUNT = 'Total Amount:',
    TAX = 'Tax',
    DELIVERY_COST = 'Delivery cost',
    ADDITIONAL_COST = 'Additional cost',
    TOTAL = 'Total',
    CARD_NUMBER = 'Card Number',
    CHANGE = 'Change',
    EXPIRATION_DATE = 'Expiration Date',
    BANK_ACCOUNT_INFORMATION = 'Bank Account Information',
    CHARGES = 'Charges',
    BREAKDOWN_OF_CHARGES = 'Breakdown of charges',
    PURCHASE_AMOUNT = 'Purchase Amount',
    SERVICE_FEE = 'Service Fee',
    TOTAL_CHARGES = 'Total Charges',
    BY_CONTINUING_YOU_AGREE = 'By continuing you agree to our ',
    TERM_AND_CONDITIONS = 'terms and conditions',
    WHY_TWO_PAYMENTS = 'Why two payment methods?',
    CONFIRMATION_NEVER = 'The transaction will processed within the next 1 - 4 days. Please note that if funds are insufficient by then, your transaction will be cancelled and may incur additional fees.',
    CONFIRMATION_2 = 'The transaction will processed within the next 1 - 4 days. Please note that if funds are insufficient by then, your card will be charged for the total amount.',
    CONFIRMATION_3 = 'The transaction will be on-hold on your card until the bank account transaction clears. If it does not, then we will continue with the charge on your card. Don’t worry we won’t double charge.',
    PLEASE_ADD_A_BANK_ACCOUNT_TO_CONTINUE = 'Please add a bank account to continue',
    PLEASE_ADD_A_CARD_TO_CONTINUE = 'Please add a card to continue',
    THIS_ACCOUNT_HAS_AN_AVAILABLE_BALANCE = 'This account has an available balance less than the total purchase.',
    LINKING_YOUR_BANK = 'linking your bank',
    MAKING_YOUR_PAYMENT = 'making your payment',
    COMPLETING_YOUR_PURCHASE = 'completing your purchase',
    SECURELY = 'securely',
    FASTER = 'faster',
    RELIABLY = 'reliably',
    NAME_ON_CARD = 'Name on Card',
    EXP_DATE = 'Exp Date',
    EXPIRY_DATE = 'Expiry Date',
    CVV = 'CVV',
    YOUR_PURCHASE_HAS_BEEN = 'Your purchase has been completed. You can now continue shopping.',
    AWESOME = 'Awesome',
    OOPS = 'Oops!',
    TRY_AGAIN = 'Try Again',
    BAD_CARD_DATA_PLEASE_ENTER_A_VALID = 'Bad card data. Please add a valid card information',
    THERE_ARE_NO_AGGREGATED_BANK_ACCOUNTS = 'There are no aggregated bank accounts',
    LOREM = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    PURCHASE = 'Purchase',
    USD = 'USD',
    CC_NEVER_MSG = 'The transaction will processed within the next 1 - 4 days. Please note that if funds are insufficient by then, your transaction will be cancelled and may incur in additional fees.',
    CC_AUTO_FALSE_CHARGE_FALLBACK_MSG = 'The transaction will processed within the next 1 - 4 days. Please note that if funds are insufficient by then, your card will be charged for the total amount.',
    CC_AUTO_TRUE_ALWAYS_AUTH_MSG = 'The transaction will be on-hold on your card until the bank account transaction clears. If it does not, then we will continue with the charge on your card. Don’t worry we won’t double charge.',
    TO_COMPLETE_YOUR_PURCHASE = 'To complete your purchase please pay:',
    PAYMENT_LINK_COMPLETED_PURCHASE = 'Purchase has been completed successfully',
    PAYMENT_LINK_DECLINED_PURCHASE = 'Purchase has been declined by the customer',
    PAYMENT_LINK_EXPIRED = 'Payment link expired',
    CANCEL_PURCHASE = 'Are you sure you want to cancel your purchase?',
    CANCEL_CONSENT = 'Are you sure you want to cancel your setting up automatic payments for',
    YOUR_ORDER_CANCELED = 'Your operation has been cancelled. You may close this page now.',
    CUSTOMER_FEE = 'Customer Fee',
    SURCHARGE_FEE = 'Surcharge Fee',
    FINANCING_FEE = 'Financing Fee',
    CLOSE = 'Close',
    PAYMENT_CODE = 'Payment code: ',
    COPYRIGHTS = '\u00A9 Copyright {0} {1}',
    PRIVATE_AND_SECURE = `Private and secure`,
    DOWNLOAD_APP = 'Download the {0} app\nand make future payments a breeze',
    GET_THE_APP = 'GET THE APP',
    NO_NEW_ACCOUNTS = 'There are no new bank accounts you can add',
    SPLIT_THE_COST = 'Split the cost of your purchase in installments almost instantaneously.',
    ALL_YOU_NEED = 'All you need to do is create an account and connect to your bank.',
    THERE_WAS_AN_ISSUE = 'There was an issue with processing your request.\n\nIf the issues persists, please contact support.',
    MISSING_PAYMENT_LINK = 'Missing payment link.',
    DETAILS = 'Details',
    I_AUTHORISE_1 = `I authorise the setup of automatic payments for`,
    I_AUTHORISE_2 = `to be deducted directly from my bank account.\n\nIf you ever decide to withdraw your consent, please contact`,
    I_AUTHORISE_3 = `Support for assistance.`,
    OOPS_WE_COULD_NOT = 'Oops! We couldn’t find any bank accounts.',
    SORRY_WE_COULD_NOT = 'Sorry, we couldn’t find any bank accounts. Try again to continue, please.',
    OOPS_HICCUP = 'Oops! There’s a hiccup in processing your payment.',
    WE_UNABLE_PROCESS_PAYMENT = 'We’re currently unable to process your payment because the merchant (ID: 192651) is not ready to accept payments. Please contact the merchant for assistance or try again later.',
    I_AGREE_TO_ALLOW = 'I agree to allow automatic payments from this account for future payments.',
    KINDLY_REQUEST = 'Kindly request a new transaction link or code to proceed.',
    OOPS_SMTH_WRONG = 'Oops, something went wrong',
    WALLET_IS_EMPTY = 'Unfortunately, your wallet is empty.',
    LET_US_CONNECT = 'Now let us connect your bank account so we can transfer your funds.',
}

export class TEXT_VARS {
    static readonly TITLE = TITLE;
    static readonly BUTTON = BUTTON;
    static readonly COMMON_TEXT = COMMON_TEXT;
    static readonly ERRORS = ERRORS;
}
