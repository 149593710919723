export enum AppRoutes {
    home = '/',
    customerLinkMyBank = '/customer/link-my-bank',
    customerLoginToBank = '/customer/login-to-bank',
    customerEnterBasicInfo = '/customer/enter-basic-info',
    customerSignIn = '/customer/sign-in',
    customerSignUp = '/customer/sign-up',
    customerLinkCreditCard = '/customer/link-credit-card',
    customerConfirmation = '/customer/confirmation',
    customerSelectYourBankAccount = '/customer/select-your-bank-account',
    customerSelectYourCard = '/customer/select-your-card',
    error = '/error',
    paymentLink = '/l',
    buyNowPayLater = '/buy-now-pay-later',
    customerReFinancing = '/customer/re-financing',
    downloadApp = '/download-app',
    addAccounts = '/customer/add-bank-accounts',
    generalError = '/general-error',
    consentLink = '/consent-link',
    consentConfirmation = '/consent-confirmation',
    transactionExpired = '/transaction-expired',
    changeRequest = '/r',
    changeConfirmation = '/customer/change-confirmation',
    walletEmpty = '/wallet-empty',
    walletClaim = '/wallet-claim',
}
